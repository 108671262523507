html,
body,#app
{
    margin:0px;
    height: 100%;
    width: 100%;
    overflow: overlay;
    background-color: #0d0d0d;
}

::-webkit-scrollbar {
    width: 16px;
}
/* ::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px grey;
border-radius: 10px;
} */

::-webkit-scrollbar-thumb {
/* background: rgb(55, 55, 55); */
/* border-radius: 10px; */
box-shadow: inset 0 0 10px 10px #282828;
border: solid 5px transparent;
border-radius: 8px;
}

/* ::selection { background: #ee7f00; } */
